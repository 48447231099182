import React from 'react';
import Head from 'next/head';
import * as PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { get_ } from 'sf-modules';

const Meta = ({ dismissSeoTags, ...props }) => {
    const router = useRouter();
    const defaultTitle = 'YEMA: Así de fácil, así de bueno';
    const title = props.title ? `${props.title} | YEMA` : defaultTitle;
    const pageUrl = (process.env.NEXT_PUBLIC_URL + router.asPath).split(/[?#]/)[0];
    const canonical = props.canonicalUrl ? props.canonicalUrl : pageUrl;
    const shouldIndex = props.shouldIndex;

    return (
        <Head>
            <title>{title}</title>

            {shouldIndex && <meta content='index, follow' name='robots' />}
            {shouldIndex && <meta content='index/ follow' name='googlebot' />}

            {!dismissSeoTags && (
                <>
                    <meta key='name' name='name' itemProp='name' content={title} />
                    <meta
                        key='description'
                        name='description'
                        itemProp='description'
                        content={props.description}
                    />
                    <meta key='image' name='image' itemProp='image' content={props.image} />
                    <meta key='keywords' name='keywords' content={props.keywords} />

                    <meta key='og:url' property='og:url' content={pageUrl} />
                    <meta key='og:type' property='og:type' content='website' />
                    <meta key='og:title' property='og:title' content={title} />
                    <meta
                        key='og:description'
                        property='og:description'
                        content={props.description}
                    />
                    <meta key='og:site_name' property='og:site_name' content={defaultTitle} />
                    <meta key='og:image' property='og:image' content={props.image} />

                    {get_(props, ['productTags', 'brand']) !== null && (
                        <meta
                            key='product:brand'
                            property='product:brand'
                            content={get_(props, ['productTags', 'brand'])}
                        />
                    )}
                    {get_(props, ['productTags', 'hasStock']) !== null && (
                        <meta
                            key='product:availability'
                            property='product:availability'
                            content={
                                get_(props, ['productTags', 'hasStock'])
                                    ? 'in stock'
                                    : 'out of stock'
                            }
                        />
                    )}
                    {get_(props, ['productTags']) !== null && (
                        <meta key='product:condition' property='product:condition' content='new' />
                    )}
                    {get_(props, ['productTags', 'price']) !== null && (
                        <meta
                            key='product:price:amount'
                            property='product:price:amount'
                            content={get_(props, ['productTags', 'price'])}
                        />
                    )}
                    {get_(props, ['productTags', 'currency']) !== null && (
                        <meta
                            key='product:price:currency'
                            property='product:price:currency'
                            content={get_(props, ['productTags', 'currency'])}
                        />
                    )}
                    {get_(props, ['productTags', 'id']) !== null && (
                        <meta
                            key='product:retailer_item_id'
                            property='product:retailer_item_id'
                            content={get_(props, ['productTags', 'id'])}
                        />
                    )}

                    <meta property='fb:app_id' content={process.env.NEXT_PUBLIC_FACEBOOK_ID} />

                    <meta key='twitter:card' name='twitter:card' content='summary_large_image' />
                    <meta key='twitter:title' name='twitter:title' content={title} />
                    <meta
                        key='twitter:description'
                        name='twitter:description'
                        content={props.description}
                    />
                    <meta key='twitter:site' name='twitter:site' content='@yemamx' />
                    <meta key='twitter:creator' name='twitter:creator' content='@yemamx' />
                    <meta key='twitter:image' name='twitter:image' content={props.image} />

                    <link rel='canonical' href={canonical} />

                    <link rel='alternate' href={pageUrl} hrefLang='x-default' />
                    <link rel='alternate' href={pageUrl} hrefLang='es-MX' />
                </>
            )}

            <meta name='google' content='notranslate' />

            <link rel='icon' type='image/ico' href='/favicon.ico' />
            <link rel='icon' type='image/png' href='/favicon.png' />
            <link rel='apple-touch-icon' href='/favicon.ico' />

            {/*<link rel="icon" type="image/ico" href="/favicon-christmas.ico" />*/}
            {/*<link rel="icon" type="image/png" href="/favicon-christmas_512x512.png" />*/}
            {/*<link rel="apple-touch-icon" href="/favicon-christmas_apple-touch-icon.png" />*/}

            <link rel='preconnect' href='https://cdn.ravenjs.com' crossOrigin='true' />
            <link rel='preconnect' href={process.env.NEXT_PUBLIC_CDN} />
        </Head>
    );
};

Meta.defaultProps = {
    title: null,
    description: 'YEMA: el primer súper consciente de México. Así de bueno. Así de fácil.',
    keywords:
        'productos saludables, productos orgánicos, comida saludable, tienda de productos orgánicos, productos naturales, productos ecológicos, tienda de productos saludables en México',
    image: '',
    canonicalUrl: null,
    shouldIndex: true,
    dismissSeoTags: false,
};

Meta.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    keywords: PropTypes.string,
    canonicalUrl: PropTypes.string,
    shouldIndex: PropTypes.bool,
    dismissSeoTags: PropTypes.bool,
};

export default Meta;
