import { stringify } from 'sf-modules';

/**
 * doCreateOrUpdateIntercom
 * @param email
 * @param newsletter
 * @param name
 * @param role
 * @param type
 * @returns {Promise<void>}
 */
export async function doCreateOrUpdateIntercom(
    email,
    newsletter = undefined,
    name = '',
    role = 'lead',
    type = ''
) {
    await fetch(`${process.env.NEXT_PUBLIC_URL}/api/intercom/create-or-update`, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify({
            email: email,
            name: name,
            role: role,
            newsletter: newsletter,
        }),
    });
}

export async function doSubscribeEmailToTag(email, tagId) {
    return await fetch(`${process.env.NEXT_PUBLIC_URL}/api/intercom/subscribe-to-tag`, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify({
            email: email,
            tagId: tagId,
        }),
    })
        .then((res) => res.json())
        .then((res) => res);
}

/**
 * Unsubscribe user or lead (Remove newsletter tag)
 */
// export async function doUnsubscribeFromNewsletterIntercom(email) {
//     await fetch(`${process.env.NEXT_PUBLIC_URL}/crm/detach`, {
//         method: 'post',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//         },
//         body: stringify({
//             email: email,
//         })
//     });
// }
