import { gql } from '@apollo/client';

const CONFIG_PAGES_QUERY = gql`
    query ConfigPages($slug: String, $first: Int) {
        configPages(slug: $slug, first: $first) {
            edges {
                node {
                    slug
                    body
                }
            }
        }
    }
`;

export default CONFIG_PAGES_QUERY;
