import { gql } from '@apollo/client';
import { CheckoutBasicFieldsFragment } from '../../fragments/checkout/CheckoutBasicFields.gql';
import { CheckoutPricingFieldsFragment } from '../../fragments/checkout/CheckoutPricingFields.gql';
import { LinesBasicFieldsFragment } from '../../fragments/checkout/LinesBasicFields.gql';
import { LinesForAnalyticsFieldsFragment } from '../../fragments/checkout/LinesForAnalyticsFields.gql';
import { LinesExtendedFieldsFragment } from '../../fragments/checkout/LinesExtendedFields.gql';

const CHECKOUT_FOR_ADD_TO_CART_QUERY = gql`
    ${CheckoutBasicFieldsFragment}
    ${CheckoutPricingFieldsFragment}
    ${LinesBasicFieldsFragment}
    ${LinesForAnalyticsFieldsFragment}
    ${LinesExtendedFieldsFragment}
    query CheckoutForAddToCart(
        $token: UUID
        $withPricingFields: Boolean = false
        $withLinesExtendedFields: Boolean = false
    ) {
        checkout(token: $token) {
            ...CheckoutBasicFields
            ...CheckoutPricingFields @include(if: $withPricingFields)
            lines {
                ...LinesBasicFields
                ...LinesForAnalyticsFields @skip(if: $withLinesExtendedFields)
                ...LinesExtendedFields @include(if: $withLinesExtendedFields)
            }
        }
    }
`;

export default CHECKOUT_FOR_ADD_TO_CART_QUERY;
