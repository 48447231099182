import { initializeApollo } from '@yema/commerce/lib/apollo';
import { get_ } from 'sf-modules';
import CONFIG_PAGES_QUERY from '@yema/commerce/graphql/queries/cms/config-pages.gql';
import { APOLLO_GRAPHQL_TIMEOUT } from 'constants/constants';

const client = initializeApollo();

export async function getHeaderPromo() {
    const responseConfigPage = await client.query({
        query: CONFIG_PAGES_QUERY,
        context: {
            clientName: process.env.NEXT_PUBLIC_API_CONTEXT_CMS,
            timeout: APOLLO_GRAPHQL_TIMEOUT,
        },
        variables: {
            first: 1,
            slug: 'config-product-page',
        },
        fetchPolicy: 'no-cache',
    });

    const parseConfigProductPage = JSON.parse(
        get_(responseConfigPage, ['data', 'configPages', 'edges', 0, 'node', 'body'], [])
    );
    return parseConfigProductPage.filter((x) => x.type === 'promo_header').map((x) => x.value);
}
