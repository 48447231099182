import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { useSelector, useDispatch } from '@yema/commerce/StoreClient';
import { useQuery } from '@yema/commerce/APIClient';
import { APP_STATE_TYPES, MODAL_KIND } from '@yema/commerce/CommerceProvider';
import { scroller } from 'react-scroll';
import { get_, decodeIdBase64, slugify, sessionstorage } from 'sf-modules';
import { checkout_reset } from '@yema/commerce/store/actions/cartActions';
import { useStateEngine } from '@yema/commerce';
import useTracker from '@yema/commerce/hooks/tracking/useTracker';
import { getHeaderPromo } from 'functions/fnRequestAPIs/fnHeaderPromo';
import { doCreateOrUpdateIntercom } from 'functions/fnRequestAPIs/fnIntercom';
import Meta from 'components/Meta';
import * as Paths from 'constants/paths';
import { EMAIL_REGEX, PROMO_HEADER } from 'constants/constants';
import CHECKOUT_FOR_ADD_TO_CART_QUERY from '@yema/commerce/graphql/queries/checkout/checkout-for-add-to-cart.gql';
import ME_QUERY from '@yema/commerce/graphql/queries/user/me.gql';

const YDSHeader = dynamic(() => import('components/layout/YDSHeader'));
const HeaderBlog = dynamic(() => import('@/yema-designkit/webkit/organisms/HeaderBlog'));
const Footer = dynamic(() => import('@/yema-designkit/webkit/organisms/Footer'), { ssr: false });
const Maintenance = dynamic(() => import('@/yema-designkit/webkit/layouts/Maintenance'), {
    ssr: false,
});

const PageLayout = ({
    staticContent,
    currentCategory,
    header,
    fullHeader,
    darkHeader,
    withHeaderSubMenu,
    footer,
    footerType,
    children,
    className,
    animatePresence,
    ...otherProps
}) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const { trackEvent } = useTracker();

    const auth = useSelector(({ authentication }) => authentication);
    const checkoutBase64Id = useSelector(({ cart }) => cart.checkoutID);
    const checkoutToken = decodeIdBase64(checkoutBase64Id);

    const [_, dispatchStateEngine] = useStateEngine();

    /** Checkout query: required to update apollo cache */
    const { loading, error, data } = useQuery(CHECKOUT_FOR_ADD_TO_CART_QUERY, {
        variables: { token: checkoutToken },
        ssr: checkoutToken,
        skip: !checkoutToken,
    });

    const { data: userData } = useQuery(ME_QUERY, {
        variables: {},
        ssr: false,
        fetchPolicy: 'cache-first',
        skip: !auth || !auth.token || router.asPath !== '/?soy-nuevo-cliente',
    });

    const [promoHeader, setPromoHeader] = useState(null);
    const [userConfigPage, setUserConfigPage] = useState(null);

    const parsePromoHeader = async () => {
        const promo = await getHeaderPromo();
        const promoHeaderData = get_(promo, [0, 'published'], false) ? get_(promo, [0]) : false;
        setPromoHeader(promoHeaderData);
        return promoHeaderData;
    };

    const subscribeNewsletter = async (value) => {
        if (value && value !== '') {
            const validateEmail = new RegExp(EMAIL_REGEX).test(value);
            if (!validateEmail) return false;

            try {
                await doCreateOrUpdateIntercom(value, true, '', 'lead', 'Footer');
                trackEvent({
                    type: 'Newsletter Subscription',
                    data: {
                        category: 'Account',
                        label: 'Footer',
                        type: 'Footer',
                    },
                });
                return true;
            } catch (error) {
                return false;
            }
        }
    };

    const handleClosePromoHeader = (key) => {
        sessionstorage.set(PROMO_HEADER, key, true);
        setUserConfigPage(key);
        dispatchStateEngine({
            type: APP_STATE_TYPES.CONFIG_SET,
            config: {
                promoHeader: {
                    visible: false,
                },
            },
        });
    };

    useEffect(() => {
        let isCancelled = false;
        if (!loading && !data && error) {
            if (isCancelled) return;
            // Invalidate checkout cookie if checkout ID expired
            dispatch(checkout_reset());
        }
        return () => {
            isCancelled = true;
        };
    }, [loading, error, data]);

    useEffect(() => {
        let scrollToAnchorTimer;
        let isCancelled = false;
        parsePromoHeader().then((promoHeaderData) => {
            if (isCancelled) return;
            const getPromoHeader = sessionstorage.get(PROMO_HEADER, true);
            dispatchStateEngine({
                type: APP_STATE_TYPES.CONFIG_SET,
                config: {
                    promoHeader: {
                        visible:
                            promoHeaderData &&
                            (getPromoHeader === null ||
                                getPromoHeader !== slugify(promoHeaderData.text)),
                    },
                },
            });
            setUserConfigPage(getPromoHeader);
            const offset =
                promoHeaderData &&
                (getPromoHeader === null || getPromoHeader !== slugify(promoHeaderData.text))
                    ? -150 // with promo header
                    : -120; // without promo header
            scrollToAnchorTimer = setTimeout(() => {
                const anchorIndex = router.asPath.indexOf('#');
                if (anchorIndex !== -1) {
                    const anchor = router.asPath.substring(anchorIndex + 1);
                    scroller.scrollTo(anchor, {
                        isDynamic: true,
                        duration: 600,
                        delay: 100,
                        smooth: true,
                        offset: offset,
                    });
                }
            }, 2000);
        });
        return () => {
            isCancelled = true;
            clearTimeout(scrollToAnchorTimer);
        };
    }, []);

    useEffect(() => {
        if (auth.token && router.asPath === '/?soy-nuevo-cliente' && userData) {
            dispatchStateEngine({
                type: APP_STATE_TYPES.TOGGLE_MODAL,
                kind: MODAL_KIND.SIGNUP_SUCCESS,
                open: true,
                props: {
                    name: auth.firstName,
                    referral: get_(userData, ['me', 'referralCode'], {}),
                },
            });
            router.push('/', { shallow: true });
        }
    }, [auth, userData]);

    if (staticContent && staticContent.withError) return <Maintenance />;

    return (
        <>
            <div className={className} {...otherProps}>
                <Meta {...staticContent.metaTags} />
                <div className='flex min-h-screen flex-col'>
                    {darkHeader ? (
                        <HeaderBlog blogUrl='/blog' storeUrl={process.env.NEXT_PUBLIC_URL} />
                    ) : (
                        header && (
                            <YDSHeader
                                fullHeader={fullHeader}
                                userLoggedIn={!!auth.token}
                                username={auth.firstName}
                                submenu={
                                    withHeaderSubMenu
                                        ? get_(
                                              staticContent,
                                              ['pageNavigations', 'menuCategories'],
                                              []
                                          )
                                        : []
                                }
                                currentCategory={currentCategory}
                                checkoutBase64Id={checkoutBase64Id}
                                userConfigPage={userConfigPage}
                                promoHeader={promoHeader}
                                handleClosePromoHeader={handleClosePromoHeader}
                            />
                        )
                    )}

                    <main style={{ flex: '1 0 auto' }} className='flex flex-col'>
                        {children}
                    </main>

                    {footer && (
                        <Footer
                            style={{ flexShrink: 0 }}
                            className=''
                            type={footerType}
                            navigation={get_(staticContent, ['pageNavigations', 'footerMenu'], [])}
                            facebookUrl={Paths.YEMA_FACEBOOK}
                            instagramUrl={Paths.YEMA_INSTAGRAM}
                            twitterUrl={Paths.YEMA_TWITTER}
                            whatsappUrl={Paths.YEMA_WHATSAPP}
                            newsletterCallback={subscribeNewsletter}
                        />
                    )}
                </div>
            </div>

            {staticContent.preview && (
                <div
                    className={cx(
                        'fixed bottom-0 flex w-full justify-center gap-6 bg-brand-primary-500 px-6 py-2 font-body',
                        'z-[9999] text-[12px]',
                        { 'mb-[34px]': auth.isStaff }
                    )}
                >
                    <strong>Preview mode: </strong>
                    <a href='/api/preview/end'>Exit preview mode</a>
                </div>
            )}

            {auth.isStaff && auth.email.endsWith('@yema.mx') && (
                <div
                    className={cx(
                        'fixed bottom-0 w-full bg-ui-error py-6 pl-6 pr-24 text-center font-body text-ui-white md:pr-6',
                        'z-[9999] text-[12px]'
                    )}
                >
                    Estás conectad@ con tu cuenta STAFF POS YEMA. Se recomienda no mezclar cuentas
                    porque puede causar errores.
                </div>
            )}
        </>
    );
};

PageLayout.defaultProps = {
    className: 'page',
    currentCategory: {},
    staticContent: {},
    header: true,
    fullHeader: true,
    withHeaderSubMenu: true,
    footer: true,
    footerType: 'full',
    darkHeader: false,
    animatePresence: false,
};

PageLayout.propTypes = {
    className: PropTypes.string,
    currentCategory: PropTypes.object,
    staticContent: PropTypes.object,
    header: PropTypes.bool,
    withHeaderSubMenu: PropTypes.bool,
    footer: PropTypes.bool,
    footerType: PropTypes.string,
    fullHeader: PropTypes.bool,
    darkHeader: PropTypes.bool,
    animatePresence: PropTypes.bool,
};

export default PageLayout;
