import { gql } from '@apollo/client';

const ME_QUERY = gql`
    query Me {
        me {
            id
            email
            firstName
            lastName
            newsletter
            referralCodeUsage
            dateJoined
            referralCode {
                conditionMessage
                code
                discountType
                referralDiscountMessage
                referralProgramTitle
                referredDiscountMessage
            }
            orders(first: 1) {
                totalCount
                edges {
                    node {
                        id
                        token
                        publicId
                        created
                        status
                        statusDisplay
                    }
                }
            }
            referrals(first: 1) {
                totalCount
            }
            rfcs {
                id
                rfc
                businessName
            }
            preferredContactChannel
            preferredContactChannelDisplay
        }
    }
`;

export default ME_QUERY;
